<template>
  <div>
    <div class="login-main">
      <div class="text-home">
        <img style="zoom:0.06" src="../../../src/assets/img/logo.png" />
      </div>
      <h1 class="brand-info__text">孕婴平台</h1>
      <div align="center" class="radio">
        <el-radio v-model="radio" label="1">个人用户</el-radio>
        <el-radio v-model="radio" label="2">机构用户</el-radio>
      </div>

      <el-tabs v-model="activeName" v-if="radio=='1'">
        <el-tab-pane label="账号登录" name="first">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" status-icon>
            <el-form-item prop="username">
              <!-- <p>地区：</p> -->
              <el-input v-model="dataForm.username" placeholder="用户名" style="font-size: 18px;"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <!-- <p>密码：</p> -->
              <el-input v-model="dataForm.password" type="password" placeholder="密码" style="font-size: 18px;"></el-input>
            </el-form-item>
            <el-form-item style="height:10px">
              <el-button style="color:dodgerblue;float:right" type="text" @click="dialogPSW = true">忘记密码？</el-button>
            </el-form-item>

            <el-form-item>
              <el-button class="login-btn-submit" type="primary" style="font-size: 18px;"
                @click="dataFormSubmit_per()">登录</el-button>
            </el-form-item>
            <el-form-item>
              <el-button class="regist-btn-submit" type="primary" style="font-size: 18px;"
                @click="gotoRegist()">还没有账号？马上注册</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="手机号登录" name="second">
          <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="mobileFormSubmit()" status-icon>
            <el-form-item>
              <!-- <p>地区：</p> -->
              <el-input v-model="dataForm.mobile" placeholder="手机号" style="width:80%; font-size: 18px;"></el-input>
              <el-button style="color:dodgerblue" type="text" @click="getCode(dataForm.mobile)">&nbsp;&nbsp;获取验证码
              </el-button>
            </el-form-item>
            <el-form-item>
              <!-- <p>密码：</p> -->
              <el-input v-model="dataForm.code" placeholder="验证码" style="font-size: 18px;"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="captcha">
            <el-row :gutter="20">
            <el-col :span="14">
                <el-input v-model="dataForm.captcha" placeholder="验证码" >
                </el-input>
            </el-col>
            <el-col :span="10" class="login-captcha">
                <img :src="captchaPath" @click="getCaptcha()" alt="" />
            </el-col>
            </el-row>
        </el-form-item> -->
            <el-form-item>
              <el-button class="login-btn-submit" type="primary" style="font-size: 18px;"
                @click="mobileFormSubmit_per()">登录</el-button>
            </el-form-item>
            <el-form-item>
              <el-button class="regist-btn-submit" type="primary" style="font-size: 18px;"
                @click="gotoRegist()">还没有账号？马上注册</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-model="activeName" v-if="radio=='2'">
        <el-tab-pane label="账号登录" name="first">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" status-icon>
            <el-form-item prop="username">
              <!-- <p>地区：</p> -->
              <el-input v-model="dataForm.username" placeholder="用户名" style="font-size: 18px;"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <!-- <p>密码：</p> -->
              <el-input v-model="dataForm.password" type="password" placeholder="密码" style="font-size: 18px;"></el-input>
            </el-form-item>
            <el-form-item style="height:10px">
              <el-button style="color:dodgerblue;float:right" type="text" @click="dialogPSW = true">忘记密码？</el-button>
            </el-form-item>

            <el-form-item>
              <el-button class="login-btn-submit" type="primary" style="font-size: 18px;"
                @click="dataFormSubmit_org()">登录</el-button>
            </el-form-item>
            <el-form-item>
              <el-button class="regist-btn-submit" type="primary" style="font-size: 18px;"
                @click="gotoRegist()">还没有账号？马上注册</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="手机号登录" name="second">
          <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="mobileFormSubmit()" status-icon>
            <el-form-item>
              <!-- <p>地区：</p> -->
              <el-input v-model="dataForm.mobile" placeholder="手机号" style="width:80%; font-size: 18px;"></el-input>
              <el-button style="color:dodgerblue" type="text" @click="getCode(dataForm.mobile)">&nbsp;&nbsp;获取验证码
              </el-button>
            </el-form-item>
            <el-form-item>
              <!-- <p>密码：</p> -->
              <el-input v-model="dataForm.code" placeholder="验证码" style="font-size: 18px;"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="captcha">
            <el-row :gutter="20">
            <el-col :span="14">
                <el-input v-model="dataForm.captcha" placeholder="验证码" >
                </el-input>
            </el-col>
            <el-col :span="10" class="login-captcha">
                <img :src="captchaPath" @click="getCaptcha()" alt="" />
            </el-col>
            </el-row>
        </el-form-item> -->
            <el-form-item>
              <el-button class="login-btn-submit" type="primary" style="font-size: 18px;"
                @click="mobileFormSubmit_org()">登录</el-button>
            </el-form-item>
            <el-form-item>
              <el-button class="regist-btn-submit" type="primary" style="font-size: 18px;"
                @click="gotoRegist()">还没有账号？马上注册</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="忘记密码-密码重置" :visible.sync="dialogPSW" width="30%" :before-close="handleClose">
      <el-form ref="psform" :model="psform" :rules="rules" label-width="100px" style="font-weight:bold">
        <el-form-item label="新密码" prop="newpsw">
          <el-input v-model="psform.newpsw" placeholder="请输入新密码" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newpsw2">
          <el-input v-model="psform.newpsw2" placeholder="请再输入一次新密码" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile" style="font-weight:bold">
          <el-input v-model="psform.mobile" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="psform.code" style="width: 70%;"></el-input>
          <el-button style="color:dodgerblue" type="text" @click="getCode(psform.mobile)">&nbsp;&nbsp;获取验证码
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPSW = false">取 消</el-button>
        <el-button v-if="radio=='1'" type="primary" @click="UpdatePSW_per(psform.newpsw, psform.newpsw2)">确 定</el-button>
        <el-button v-if="radio=='2'" type="primary" @click="UpdatePSW_org(psform.newpsw, psform.newpsw2)">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getUUID } from '@/utils'
// import '../../assets/theme/el-input/theme/index.css'
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.psform.newpsw2 !== '') {
          this.$refs.psform.validateField('newpsw2');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.psform.newpsw) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      dialogPSW: false,
      activeName: 'first',
      psform: {
        newpsw: '',
        newpsw2: '',
        code: '',
        mobile: '',
      },
      rules: {
        newpsw: [
          { validator: validatePass, trigger: 'blur' }
        ],
        newpsw2: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      url: 'url',
      dataForm: {
        username: '',
        password: '',
        mobile: '',
        code: '',
      },
      dataRule: {
        username: [
          { required: true, message: '地区不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        // captcha: [
        //   { required: true, message: '验证码不能为空', trigger: 'blur' }
        // ]
      },
      captchaPath: '',
      // allUrl: ['/sys/login', '/sys/doctor', '/sys/admin'],
      // url: '/sys/login'
      radio: '1',
    }
  },
  created() {

  },
  computed: {
    userId: {
      get() { return this.$store.state.women_baby.uid },
      set(val) { this.$store.commit('women_baby/updateUId', val) }
    },
    username: {
      get() { return this.$store.state.women_baby.username },
      set(val) { this.$store.commit('women_baby/updateUsername', val) }
    },
    last_login: {
      get() { return this.$store.state.women_baby.lastlogin },
      set(val) { this.$store.commit('women_baby/updateLastLogin', val) }
    },
    identity: {
      get() { return this.$store.state.women_baby.identity },
      set(val) { this.$store.commit('women_baby/updateIdentity', val) }
    }
  },
  methods: {
    // 提交表单--账号登录
    dataFormSubmit_per() {
      // this.$router.replace({ name: 'myhealth' })
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          var path = '/personal/pwd/login'   // 根据登录角色来定提交表单的地址
          console.log(this.dataForm)
          this.$http({
            url: this.$http.adornUrl(path),
            method: 'post',
            data: this.$http.adornData({
              username: this.dataForm.username,
              password: this.dataForm.password,
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              console.log("看看登录时返回的东西")
              console.log(data)
              this.$cookie.set('token', data.token)  // 登录成功设置请求令牌
              this.userId = data.uid
              this.username = data.username
              this.last_login = data.last_login
              this.identity = this.radio
              this.$router.replace({ name: 'WomenBabyhome' })
            } else {
              // this.getCaptcha()
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    // 提交表单--手机号登录
    mobileFormSubmit_per() {
      this.$http({
        url: this.$http.adornUrl("/personal/phone/login"),
        method: "post",
        data: this.$http.adornData({
          userPhone: this.dataForm.mobile,
          code: this.dataForm.code
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$message({
            message: '手机号登录成功！',
            type: 'success'
          });
          this.$cookie.set('token', data.token)  // 登录成功设置请求令牌
          this.userId = data.uid
          this.username = data.username
          this.last_login = data.last_login
          console.log(this.last_login)
          this.$router.replace({ name: 'WomenBabyhome' })
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    // 获取图片验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID()
      this.captchaPath = this.$http.adornUrl(
        `/captcha.jpg?uuid=${this.dataForm.uuid}`
      )
    },
    // 转到注册界面
    gotoRegist() {
      this.$router.replace({ name: 'regist' })
    },
    // 忘记密码页
    forgetpsw() {

    },
    // 获取短信验证码
    getCode(phone_number) {
      this.$http({
        url: this.$http.adornUrl("/sms/sendCode"),
        method: "get",
        params: this.$http.adornParams({ phone: phone_number }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$message({
            message: '验证码已发至您的手机，请注意查收！',
            type: 'success'
          });
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    UpdatePSW_per(psw1, psw2) {
      if (psw1 == psw2) {
        this.$http({
          url: this.$http.adornUrl("/personal/user/updatePassword"),
          method: "post",
          data: this.$http.adornData({
            userPhone: this.psform.mobile,
            code: this.psform.code,
            password: psw1
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.dialogPSW = false
            this.$message({
              message: '密码重置成功！',
              type: 'success'
            });
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg);
          }
        });
        this.$message({
          message: '密码传参成功！',
          type: 'success'
        });
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
  // radio='2'---管理者
  dataFormSubmit_org() {
      // this.$router.replace({ name: 'myhealth' })
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          var path = '/enterprise/pwd/login'   // 根据登录角色来定提交表单的地址
          console.log(this.dataForm)
          this.$http({
            url: this.$http.adornUrl(path),
            method: 'post',
            data: this.$http.adornData({
              username: this.dataForm.username,
              password: this.dataForm.password,
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              console.log("看看登录时返回的东西")
              console.log(data)
              this.$cookie.set('token', data.token)  // 登录成功设置请求令牌
              this.$cookie.set('eid', data.eid)  // 登录成功设置请求令牌
              this.$cookie.set('ename', data.ename)
              // this.docId = data.docId
              // this.docName = data.doctorName
              this.userId = data.eid
              this.username = data.ename
              this.last_login = data.last_login
              this.identity = this.radio
              // console.log(this.last_login)
              this.$router.replace({ name: 'WomenBabyhome' })
            } else {
              // this.getCaptcha()
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    // 提交表单--手机号登录
    mobileFormSubmit_org() {
      this.$http({
        url: this.$http.adornUrl("/doctor/phone/login"),
        method: "post",
        data: this.$http.adornData({
          userPhone: this.dataForm.mobile,
          code: this.dataForm.code
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$message({
            message: '手机号登录成功！',
            type: 'success'
          });
          this.$cookie.set('token', data.token)  // 登录成功设置请求令牌
          this.userId = data.uid
          this.username = data.username
          this.last_login = data.last_login
          console.log(this.last_login)
          this.$router.replace({ name: 'WomenBabyhome' })
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    UpdatePSW_org(psw1, psw2) {
      if (psw1 == psw2) {
        this.$http({
          url: this.$http.adornUrl("/personal/user/updatePassword"),
          method: "post",
          data: this.$http.adornData({
            userPhone: this.psform.mobile,
            code: this.psform.code,
            password: psw1
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.dialogPSW = false
            this.$message({
              message: '密码重置成功！',
              type: 'success'
            });
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg);
          }
        });
        this.$message({
          message: '密码传参成功！',
          type: 'success'
        });
      }
    },
  },


}
</script>

<style lang="scss">
// .brand-info__text {
//   text-align: center;
//   font-size: 34px;
//   padding:15px;
//   font-weight: bold;
//   text-transform: uppercase;

// }

.text-home {
  text-align: center;
  font-size: 30px;
  color: firebrick;
  margin-bottom: 10px;
}

.login-main {
  position: absolute;
  top: 50%;
  left: 50%;
  // padding: 150px 60px 180px;
  width: 500px;
  transform: translate(-50%, -50%);
  // min-height: 100%;
  // background-color: #fff;
  // z-index: 9999;
}

.login-title {
  font-size: 24px;
}

.login-captcha {
  overflow: hidden;

  >img {
    width: 100%;
    cursor: pointer;
  }
}

.login-btn-submit {
  width: 100%;
  // margin-top: 25px;
  font-size: 18px;
  background-color: #428bca;
  border-color: #428bca;
}

.regist-btn-submit {
  width: 100%;
  font-size: 18px;
  // margin-top: 8px;
  background-color: #428bca;
  border-color: #428bca;
}
</style>
<style lang="scss" scoped>
::v-deep .tabs {
  text-align: center;
  top: 50px;
  left: 50px;
  position: relative;
}

::v-deep .el-tabs {
  color: #000;
  left: 0px;
  top: 0px;
  position: relative;
}

/* 去除灰色横条 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/* 设置滑块颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #428bca !important;
}

/* 设置滑块停止位置 */
::v-deep .el-tabs__active-bar.is-top {
  height: 37px;
  width: 120px ! important;
  border-radius: 17px;
  top: 0px !important;
  position: absolute !important;
  z-index: 1;
}

/* 设置当前选中样式 */
::v-deep .el-tabs__item.is-active {
  color: #ffffff !important;
  z-index: 2;
}

/* 设置未被选中样式 */
::v-deep .el-tabs__item {
  padding: 0 0px !important;
  width: 120px;
  box-sizing: border-box;
  display: inline-block;
  position: relative !important;
  color: #000000 !important;
  z-index: 2;
  font-size: 18px;
  text-align: center;
}

// :v-deep .el-input__inner {
//     &::placeholder {
//       color: red !important; 
//       font-size: 16px !important; 
//     }
// }

.el-radio /deep/ .el-radio__label{

font-size:20px !important;
font-weight: bold;

}
</style>
